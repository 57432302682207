<template>
  <div class="home">
    <h1>Trivial pour Geek</h1>
    <img alt="logo" src="../assets/atom.png">

      <form @submit.prevent.once="scoring">
        <transition appear name="fade">
          <div class="blocs">
            <QuestionCard v-for="question in questions" :key="question.id" :question="question" :submit="submit" @question-score="allScores"/>
          </div>
        </transition>
          <button v-if="!showScore">On compte les points?</button>
          <button v-if="showScore">Nouvelle partie?</button>

          <p v-if="showScore && totalScore==4"> Bravo! Votre score est de {{ totalScore }} /4</p>
          <p v-if="showScore && totalScore>0 && totalScore<4"> Pas mal! Votre score est de {{ totalScore }} /4</p>
          <p v-if="showScore && totalScore==0"> Aie aie aie! Votre score est de {{ totalScore }} /4</p>

      </form>

  </div>    
</template>

<script>
// @ is an alias to /src
import QuestionCard from '@/components/QuestionCard.vue'
import {getRandomQuestions} from '../services/EventService'

export default {
  name: 'Home',
  components: {
    QuestionCard
  },
  data() {
    return {
      questions: null,
      totalScore: 0,
      allScoresArray: [0,0,0,0],
      showScore: false,
      submit: false
    }
  },
  created() {
    getRandomQuestions()
    .then(response => {
      this.questions = response.data.result
    })
    .catch(error => {console.log(error)})
  },
  methods:{
    scoring(){
        for (let i=0; i<4; i++) {
          this.totalScore += this.allScoresArray[i];
        }
          this.showScore = true;
          this.submit = true;
    },
    allScores(score,id) {
      for(let i=0; i<4; i++) {
        if (this.questions[i].id == id) {
          this.allScoresArray[i] = score;
          return;
        }
      }
    },
  }
}

</script>

<style scoped>

.home {
  width: 700px;
  margin: auto;
}
.home img {
  margin: 30px auto;
  height: 80px;
  width: 80px;
}

.blocs {
  margin: 15px auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);

}

button {
  background:#F1C40F;
  background:linear-gradient(#F1C40F, #F2D873);
  box-shadow: 0px 1px 21px 0px #fff6af;
  border-radius:11px;
  margin: 30px;
  padding:20px 45px;
  display:inline-block;
  text-align:center;
  border:none;
  font-family:Orbitron, Helvetica, Arial, sans-serif;
  font-weight:800;
  cursor: pointer;
}

button:hover {
  transform: scale(1.1);
    box-shadow: 0px 1px 5px 0px #fff6af;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>
