<template>
  
  <div class="question" :class="classObject">

        <p>{{question.question}}</p>
        <div class="answers">
          <div>
            <label :for="question.answer1">
              <input type="radio" :name="question.id" :id="question.answer1" :value="question.answer1"  v-model="selectedAnswer" v-on:change="sendScore">
              {{question.answer1}}</label>
          </div>
          <div>
            <label :for="question.answer2">
              <input type="radio" :name="question.id" :id="question.answer2" :value="question.answer2" v-model="selectedAnswer" v-on:change="sendScore">
              {{question.answer2}}</label>
          </div>
          <div>
            <label :for="question.answer3">
              <input type="radio" :name="question.id" :id="question.answer3" :value="question.answer3" v-model="selectedAnswer" v-on:change="sendScore">
              {{question.answer3}}</label>
          </div>
        </div>
  </div>
   
</template>

<script>
export default {
  name: 'QuestionCard',
  props: {
    question: Object,
    submit: Boolean
  },
  data() {
    return {    
      selectedAnswer: undefined,
      currentQuestion: null,
    }
  },
  mounted() {
    this.currentQuestion = this.question;
  },
  computed: {
    score: function() {
      let check = (this.selectedAnswer == this.currentQuestion.correctAnswer)
      return check? 1 : 0;
    },
    classObject: function() {
      return {
      wrong: this.submit && this.score == 0,
      right: this.submit && this.score == 1
      }
    }
  },
  methods: {
    sendScore(){
      this.$emit('question-score', this.score, this.currentQuestion.id)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.question {
  position: relative;
  text-align: left;
  width: 330px;
  height: 200px;
  margin: 20px auto 0px;
  padding: 20px 20px;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9em;
  color: #2C3E50 ;
  /* border: solid black 1px; */
  border-radius: 15px;
  background: #E5E7E9;
}

.question:hover {
  transform: scale(1.01);
  -webkit-box-shadow: 0px 10px 13px -7px #EAECEE, 5px 5px 15px 5px rgba(0,0,0,0); 
  box-shadow: 0px 10px 13px -7px #EAECEE, 5px 5px 15px 5px rgba(0,0,0,0);
}

.answers {
  position: absolute;
  bottom: 20px;
}

input {
  margin: 10px;
  transform: scale(1.5);
}

ul {
  padding: 0;
  margin: 0;
}
li {
  list-style-type: none;
  text-align: left;
}

.wrong {
  background: #929395;
}

.right {
  background: #F1C40F;
}


</style>