import axios from "axios";

const apiClient = axios.create({
    baseURL: "http://localhost:8080/api/"
})


export function getQuestions(){
        return apiClient.get('/questions')
}

export function getRandomQuestions(){
    return apiClient.get('/random4')
}