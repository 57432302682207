<template>
  <div id="nav">
    <router-link to="/">Quizz</router-link> |
    <router-link to="/about">A propos</router-link>
  </div>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');

*, ::before, ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
#app {
  width: 100vw;
  height: 100vh;
  background-color: #2C3E50;

  font-family: Orbitron, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #EAECEE;
}

#nav {
  width: 700px;
  margin: auto;
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #EAECEE;
}

#nav a.router-link-exact-active {
  color: #F1C40F;
}
</style>
